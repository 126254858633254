.mild {
  fill: #53c319;
}

.warm {
  fill: #fcba03;
}

.no-data {
  fill: #ececec;
}

.hot {
  fill: #cf4226;
}

.cool {
  fill: #3fc0a9;
}

.cold {
  fill: #2d89d6;
}

.focus-region {
  stroke: black;
    stroke-width: 2px;
    filter: brightness(0.95);
}

.map-container {
  position: relative;
}

.map-wrapper {
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  position: relative;
}


