@media (min-width: 992px) {
  .legend {
    margin: 20px;
    margin-right: 200px;
  }
}

.legend {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding : 20px;
  text-align: left;
  font-size: 12px;
  pointer-events:none;
}

ul li::before {
  content: "\25A0";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

ul {
  list-style: none;
}

ul li.red::before {
  color: #cf4226;
}

ul li.yellow::before {
  color: #fcba03;
}

ul li.green::before {
  color: #53c319;
}

ul li.turquoise::before {
  color: #3fc0a9;
}

ul li.blue::before {
  color: #2d89d6;
}